import { Link } from "react-router-dom";

import styles from "../Styles/footer.module.css";

function Users(props) {
  const { products, AddProduct } = props;

  return (
    <div>
      <div>
        {products.map((e) => (
          <div style={{ display: "flex", margin: "0 auto", padding: "20px" }} key={e.id}>
            <img style={{ width: "100px", height: "100px" }} src={e.image_url} />
            <h2 style={{ marginLeft: "20px" }}> {e.product_name} </h2>
            <h2 style={{ marginLeft: "20px", color: "red" }}>Price : {e.price}</h2>
          </div>
        ))}
      </div>

      <div className={styles.footermain}>
        <div className={styles.firstleftbox}>
          <div className={styles.mainheading}>
            <h1 style={{ textAlign: "center", fontSize: "12px", margin: "0" }}>India's no.1 destination for tech & design </h1>
          </div>
          <div className={styles.mainheading2}>
            <p style={{ textAlign: "center", fontSize: "12px", margin: "0", marginTop: "20px" }}>
              Founded on the principal that good design should be seamless and accessible to all; Studio Proper creates an evolving range of solutions
              and accessories that deliver great technology experiences, right to your doorstep.{" "}
            </p>
          </div>
          <div className={styles.socialmedialogos}>
            <img style={{ width: "25px", height: "25px", marginLeft: "80px" }} src="Images/facebook.png"></img>
            <img style={{ width: "25px", height: "25px" }} src="Images/insta.jpg"></img>
            <img style={{ width: "25px", height: "25px" }} src="Images/linkdin.png"></img>
            <img style={{ width: "25px", height: "25px" }} src="Images/twitter.jpg"></img>
            <img style={{ width: "25px", height: "25px" }} src="Images/youtube.png"></img>
          </div>
        </div>
        <div className={styles.middlebox}>
          <div className={styles.mainheading}>
            <h1 style={{ textAlign: "center", fontSize: "12px", margin: "0" }}>Studio Proper </h1>
          </div>
          <div className={styles.mainheading2}>
            <p style={{ textAlign: "center", fontSize: "12px", margin: "0" }}>
              Verified Customer Reviews <br /> Apple Authorised Reseller <br /> Buy Now Pay Later <br /> NDIS support available <br /> Proper x
              Architects
              <br /> Custom Solutions <br /> Your Brand on Our Products Privacy Policy
            </p>
          </div>
        </div>
        <div className={styles.middlebox}>
          <div className={styles.mainheading}>
            <h1 style={{ textAlign: "center", fontSize: "12px", margin: "0" }}>Journal </h1>
          </div>
          <div className={styles.mainheading2}>
            <p style={{ textAlign: "center", fontSize: "12px", margin: "0" }}>
              All Articles <br /> Apple introduce new Iphone 13 range <br /> Dogtag update #4-Production Samples <br /> Dogtag update #3-Tooling and
              Production <br /> Dogtag update #2-Fit, form & function
              <br /> Dogtag update #1- Safety, refinement <br /> Proper Vent Mount for Apple MagSafe
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
