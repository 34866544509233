import logo from "./logo.svg";
import "./App.css";

import { Route, Switch } from "react-router-dom";
import { Navbar } from "./components/Navbar";

import Welcome from "./components/Welcome";

import HomeCartPage from "./components/HomeCartPage";

import data from "./data";
import { useState } from "react";
import CustomerSupport from "./components/CustomerSupport";
import styles from "./Styles/footer.module.css";

function App() {
  const { products } = data;

  return (
    <div className="App">
      <h6 style={{ width: "100%", height: "20px", margin: "0", border: "1px solid black", background: "#000000", color: "white" }}>
        Free India wide shipping - Flat rate global shipping
      </h6>
      <Navbar />

      <br />
      <Switch>
        <Route exact path="/"></Route>

        <Route exact path="/allproducts">
          <HomeCartPage products={products}></HomeCartPage>
        </Route>

        <Route path="/customersupport">
          <CustomerSupport />
        </Route>

        <Route path="/aboutus">Welcome to Acccount Page</Route>
        <Route path="/search">Welcome to Search Page</Route>
        <Route path="/cartPage">Welcome to Cart Page</Route>

        <Route>404 not found</Route>
      </Switch>
    </div>
  );
}

export default App;
