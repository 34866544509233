import { Link } from "react-router-dom";

export const Navbar = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #eaeaea",
        marginTop: "30px",
        width: "1230px",
        height: "50px",
        backgroundColor: "#FFFFFF",
        margin: "0",
      }}
      className="App"
    >
      <Link to="/">
        <img style={{ width: "100px", height: "70px", marginTop: "10px", marginLeft: "35px" }} src="Images/Properlogo.png"></img>
      </Link>
      <br />

      <Link style={{ textDecoration: "none" }} to="/allproducts">
        <h5 style={{ marginTop: "30px", color: "#000000" }}>Shop</h5>
      </Link>
      <br />

      <Link style={{ textDecoration: "none" }} to="/customersupport">
        <h5 style={{ marginTop: "30px", color: "#000000" }}>Customer Support</h5>
      </Link>

      <Link style={{ textDecoration: "none" }} to="/aboutus/">
        <h5 style={{ marginTop: "30px", color: "#000000", marginLeft: "600px" }}>Account</h5>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/search/">
        <h5 style={{ marginTop: "30px", color: "#000000" }}>Search Page</h5>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/cartpage/">
        <h5 style={{ marginTop: "30px", color: "#000000", textDecoration: "none" }}>Cart(0)</h5>
      </Link>
      <br />
    </div>
  );
};
