const data = {
  products: [
    {
      id: 1,
      product_name: "Iphone 13 Pro ",
      image_url:
        "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/iphone-13-pro-blue-select?wid=940&hei=1112&fmt=png-alpha&.v=1631652954000",
      description: "light blue",

      price: 132000,
    },
    {
      id: 2,
      product_name: "Iphone 12 Pro",
      image_url: "https://store.storeimages.cdn-apple.com/4668/as-images.apple.com/is/MHKK3?wid=2000&hei=2000&fmt=jpeg&qlt=95&.v=1603649004000",
      description: "blue",
      price: 98000,
    },
    {
      id: 3,
      product_name: "Iphone 12 Pro",
      image_url: "https://www.aptronixindia.com/media/catalog/product/i/p/iphone-12-pro-blue-hero.png",
      description: "Grey",
      price: 99000,
    },
  ],
};

export default data;
